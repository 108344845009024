<template>
  <div
    class="
      widgetContainer
      bg-white
      contactSuccess
      appStatus appStatus--approved
    ">
    <div class="navigationHeader drawer-header-default" v-if="this.page === 'contact'">
      <div class="navigationHeader__inner">
        <p class="title">
          Contact
        </p>
        <span class="el-icon-close" @click="done" />
      </div>
    </div>
    <div class="widgetContainer__body">
      <LottieAnimation animation-name="success" reference="contact-created-success" :loop="true" />
      <p class="title">
        {{ $t('contact_creation_title') }}
      </p>
      <p class="description">
        {{ $t('contact_creation_description') }}
      </p>
    </div>
    <div class="el-drawer__footer">
      <el-button
        type="primary"
        class="width-100 el-button__brand brand"
        @click="openContactDetails"
        v-if="this.page === 'contact'">
        {{ $t('contact_Details_Title') }}
      </el-button>
      <el-button
        type="primary"
        class="width-100 el-button__brand brand"
        @click="done"
        v-else>
        {{ $t('done') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import LottieAnimation from '@/components/LottieAnimation.vue';

export default {
  components: {
    LottieAnimation
  },
  props: {
    page: {
      type: String,
      default: 'contact'
    }
  },
  methods: {
    done() {
      setTimeout(() => {
        
        if(this.page === 'check-deposit') {
          this.drawerReplace('CheckDeposit-contacts');
        } else {
          this.$emit('fetchContacts');
          this.drawerClose();
        }
      }, 0);
    },
    openContactDetails() {
      this.drawerClose();
      this.$emit('fetchContacts');
      this.$emit('openContactDeatilsDrawer');
    }
  }
};
</script>
